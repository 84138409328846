"use client";

import React, { useEffect, useCallback } from "react";
import Image from "next/image";

import "./SplashScreen.css";

interface AppSplashProps {
  onComplete: () => void;
  duration?: number;
}

/**
 * Initial app splash screen shown at the very beginning of the app load
 * This is completely separate from authentication and focuses only on initial branding/loading
 */
export const AppSplash: React.FC<AppSplashProps> = ({ 
  onComplete, 
  duration = 2000 // Default display duration in milliseconds
}) => {
  // Handle completion of splash screen timer
  const handleComplete = useCallback(() => {
    if (onComplete) {
      onComplete();
    }
  }, [onComplete]);
  
  // Setup timer for splash screen duration
  useEffect(() => {
    const timer = window.setTimeout(() => {
      handleComplete();
    }, duration);
    
    return () => {
      window.clearTimeout(timer);
    };
  }, [duration, handleComplete]);
  
  return (
    <div 
      className="appSplash"
      data-testid="app-splash-screen"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#141420',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
        overflow: 'hidden'
      }}
    >
      {/* Pulse background similar to login screen */}
      <div 
        style={{
          position: 'absolute',
          inset: 0,
          background: 'radial-gradient(circle, rgba(249, 168, 212, 0.15) 0%, rgba(0, 0, 0, 0.8) 70%, transparent 100%)',
          zIndex: -1,
          animation: 'pulseBackground 8s ease-in-out infinite'
        }}
      />
      
      {/* Logo container */}
      <div
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <Image 
          src="/LoobLogo.png"
          alt="Loob Logo"
          width={120}
          height={120}
          priority={true}
          style={{ 
            objectFit: 'contain'
          }}
        />
      </div>
    </div>
  );
}; 