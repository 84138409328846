'use client';

import React, { useEffect, useState } from 'react';
import { useGlobalState } from './GlobalStateContext';

interface AuthInitializerProps {
  children: React.ReactNode;
}

/**
 * AuthInitializer handles auth state without blocking the app
 * It renders children immediately and handles auth initialization in the background
 */
export const AuthInitializer: React.FC<AuthInitializerProps> = ({ children }) => {
  const { manualInit } = useGlobalState();
  const [hasAttemptedInit, setHasAttemptedInit] = useState(false);

  // We use setTimeout to push initialization to a future tick to avoid blocking
  useEffect(() => {
    if (hasAttemptedInit) return;

    // Use a microtask to defer initialization until after render without blocking
    const initTimeout = setTimeout(() => {
      try {
        // This will be called after the component renders
        manualInit();
      } catch (error) {
        console.error('Error during auth initialization:', error);
      } finally {
        setHasAttemptedInit(true);
      }
    }, 500); // Small delay to ensure splash screen renders first

    return () => clearTimeout(initTimeout);
  }, [manualInit, hasAttemptedInit]);

  // Always render children immediately
  return <>{children}</>;
}; 