'use client';

import React, { useState, useEffect } from "react";
import { ModalProvider } from "./ModalContext";
import { useAuth } from "../hooks/useAuth";
import { LoginScreen } from "./LoginScreen";
import { AppSplash } from "./AppSplash";
import Script from 'next/script';

// PWA Service Worker Registration
const registerServiceWorker = () => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.location.hostname !== 'localhost') {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register('/sw.js');
    });
  }
};

export function ClientLayout({ children }: { children: React.ReactNode }) {
  const [showSplash, setShowSplash] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [appReady, setAppReady] = useState(false);
  const { isAuthenticated, authReady } = useAuth();

  // Initialize app state
  useEffect(() => {
    // Register service worker
    registerServiceWorker();
    
    // Set viewport height
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    setViewportHeight();
    window.addEventListener('resize', setViewportHeight);
    
    return () => window.removeEventListener('resize', setViewportHeight);
  }, []);

  // Handle authentication and splash screen logic
  useEffect(() => {
    if (!authReady) return;
    
    // If already authenticated, just wait for splash to complete
    if (isAuthenticated) {
      // User already logged in
    } else {
      // Need to show login after splash
      setShowLogin(true);
    }
  }, [authReady, isAuthenticated]);

  // Handle splash screen completion
  const handleSplashComplete = () => {
    setShowSplash(false);
    
    // If authenticated, show the app, otherwise LoginScreen is already set to show
    if (isAuthenticated) {
      setAppReady(true);
    }
  };

  // Handle successful login
  const handleLoginComplete = () => {
    setShowLogin(false);
    setAppReady(true);
  };

  // Rendering logic
  // 1. First show splash screen
  if (showSplash) {
    return <AppSplash onComplete={handleSplashComplete} />;
  }
  
  // 2. After splash, show login if needed
  if (showLogin && !isAuthenticated) {
    return <LoginScreen onComplete={handleLoginComplete} />;
  }
  
  // 3. Finally, show the main app
  if (appReady || isAuthenticated) {
    return (
      <ModalProvider>
        {children}
        
        {/* PWA install prompt script */}
        <Script 
          id="pwa-install-prompt" 
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              let deferredPrompt;
              window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                deferredPrompt = e;
                // Show your custom PWA install button or UI if needed
                if (window.showPWAInstallPrompt) {
                  window.showPWAInstallPrompt(true);
                }
              });
              
              // Add a function to the window to trigger the prompt when needed
              window.installPWA = () => {
                if (deferredPrompt) {
                  deferredPrompt.prompt();
                  deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === 'accepted') {
                      console.log('User accepted the PWA installation');
                      if (window.showPWAInstallPrompt) {
                        window.showPWAInstallPrompt(false);
                      }
                    }
                    deferredPrompt = null;
                  });
                }
              };
            `
          }}
        />
      </ModalProvider>
    );
  }
  
  // Fallback loading state
  return (
    <div className="min-h-screen bg-background-primary flex items-center justify-center">
      <div className="text-center">
        <div className="animate-pulse text-2xl text-pink-300 mb-2">●●●</div>
        <p className="text-gray-400">Loading app...</p>
      </div>
    </div>
  );
} 