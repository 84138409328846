import(/* webpackMode: "eager", webpackExports: ["AuthInitializer"] */ "/vercel/path0/components/AuthInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientLayout"] */ "/vercel/path0/components/ClientLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStateProvider"] */ "/vercel/path0/components/GlobalStateContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-primary\"}],\"variableName\":\"spaceGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Comfortaa\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-loob\",\"display\":\"swap\",\"fallback\":[\"Quicksand\",\"Outfit\",\"Nunito\",\"Varela Round\",\"sans-serif\"],\"preload\":true,\"adjustFontFallback\":true}],\"variableName\":\"comfortaa\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
